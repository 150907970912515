// 导航网站相关的数据
export let navData = [
  {
    menu: {
      title: `工具`,
      subMenu: [
        {
          title: `免费`,
          tabs: [
            {
              tabTitle: `图片`,
              navArr: [
                {
                  title: `封面`,
                  linkArr: [
                    {
                      title: `VideoCover`,
                      info: `随机生成视频封面`,
                      icon: ``,
                      linkUrl: `https://videocover.smallzhiyuns.com/`,
                    },
                  ],
                },
              ],
            },
            {
              tabTitle: `字幕`,
              navArr: [
                {
                  title: `Srt`,
                  linkArr: [
                    {
                      title: `Whisper Api`,
                      info: `使用 Whisper API 生成字幕`,
                      icon: ``,
                      linkUrl: `https://audiotosrt.smallzhiyuns.com/`,
                    },
                    {
                      title: `Traditional to Simplified`,
                      info: `繁体转为简体字幕`,
                      icon: ``,
                      linkUrl: `https://t2csrt.smallzhiyuns.com/`,
                    },
                  ],
                },
              ],
            },
            {
              tabTitle: `转码`,
              navArr: [
                {
                  title: `音频`,
                  linkArr: [
                    {
                      title: `VideoToAudio`,
                      info: `视频转为音频`,
                      icon: ``,
                      linkUrl: `https://videotoaudio.smallzhiyuns.com/`,
                    },
                  ],
                },
              ],
            },
            {
              tabTitle: `频道`,
              navArr: [
                {
                  title: `YouTube Api V3`,
                  linkArr: [
                    {
                      title: `Channel ID`,
                      info: `获取YouTube频道唯一ID`,
                      icon: ``,
                      linkUrl: `https://channel.smallzhiyuns.com/`,
                    },
                  ],
                },
              ],
            },
            {
              tabTitle: `通知`,
              navArr: [
                {
                  title: `IOS`,
                  linkArr: [
                    {
                      title: `BarkSeerver`,
                      info: `ios上的通知服务`,
                      icon: ``,
                      linkUrl: `https://barkserver.dsx2020.com/`,
                    },
                  ],
                },
              ],
            },
            {
                tabTitle: `创作`,
                navArr: [
                  {
                    title: `绘画`,
                    linkArr: [
                      {
                        title: `Excalidraw`,
                        info: `在线白板手绘`,
                        icon: ``,
                        linkUrl: `https://excalidraw.dsx2020.com/`,
                      },
                    ],
                  },
                ],
              },
          ],
        },
        {
          title: `付费`,
          tabs: [
            {
              tabTitle: `获利`,
              navArr: [
                {
                  title: `交易`,
                  linkArr: [
                    {
                      title: `qqtube`,
                      info: `一个月开通获利`,
                      icon: ``,
                      linkUrl: `https://www.qqtube.com/?ref=991233`,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
