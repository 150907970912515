// 导航网站相关的数据
export let navDataEn = [
  {
    menu: {
      title: `Tools`,
      subMenu: [
        {
          title: `free`,
          tabs: [
            {
              tabTitle: `Image`,
              navArr: [
                {
                  title: `Cover`,
                  linkArr: [
                    {
                      title: `VideoCover`,
                      info: `Randomly generate video cover`,
                      icon: ``,
                      linkUrl: `https://coverimg.dsx2016.com/`,
                    },
                    {
                      title: `cropImg`,
                      info: `Crop Image`,
                      icon: ``,
                      linkUrl: `https://cropimg.dsx2016.com/`,
                    },
                  ],
                },
              ],
            },
            {
              tabTitle: `Subtitle`,
              navArr: [
                {
                  title: `Srt`,
                  linkArr: [
                    // {
                    //   title: `Whisper Api`,
                    //   info: `Generate subtitles using Whisper API`,
                    //   icon: ``,
                    //   linkUrl: `https://audiotosrt.smallzhiyuns.com/`,
                    // },
                    {
                      title: `Traditional to Simplified`,
                      info: `Traditional to Simplified`,
                      icon: ``,
                      linkUrl: `https://t2csrt.dsx2016.com/`,
                    },
                  ],
                },
              ],
            },
            {
              tabTitle: `Transcoding`,
              navArr: [
                {
                  title: `Audio`,
                  linkArr: [
                    {
                      title: `VideoToAudio`,
                      info: `Convert video to audio`,
                      icon: ``,
                      linkUrl: `https://toaudio.dsx2016.com/`,
                    },
                  ],
                },
              ],
            },
            // {
            //   tabTitle: `Channel`,
            //   navArr: [
            //     {
            //       title: `YouTube Api V3`,
            //       linkArr: [
            //         {
            //           title: `Channel ID`,
            //           info: `Get the unique channel ID`,
            //           icon: ``,
            //           linkUrl: `https://channel.smallzhiyuns.com/`,
            //         },
            //       ],
            //     },
            //   ],
            // },
            // {
            //   tabTitle: `Notification`,
            //   navArr: [
            //     {
            //       title: `IOS`,
            //       linkArr: [
            //         {
            //           title: `BarkSeerver`,
            //           info: `ios mobile phone message notification`,
            //           icon: ``,
            //           linkUrl: `https://barkserver.dsx2020.com/`,
            //         },
            //       ],
            //     },
            //   ],
            // },
            // {
            //     tabTitle: `Creation`,
            //     navArr: [
            //       {
            //         title: `Drawing`,
            //         linkArr: [
            //           {
            //             title: `Excalidraw`,
            //             info: `Image Hosting solution`,
            //             icon: ``,
            //             linkUrl: `https://excalidraw.dsx2020.com/`,
            //           },
            //         ],
            //       },
            //     ],
            //   },
          ],
        },
        {
          title: `payment`,
          tabs: [
            {
              tabTitle: `Profit`,
              navArr: [
                {
                  title: `trade`,
                  linkArr: [
                    {
                      title: `qqtube`,
                      info: `Profit after one month of opening`,
                      icon: ``,
                      linkUrl: `https://www.qqtube.com/?ref=991233`,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
