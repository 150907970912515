import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, unref as _unref, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  "class": "navPage"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  style: {
    "position": "relative"
  }
};
var _hoisted_4 = {
  "class": "el-dropdown-link"
};
var _hoisted_5 = {
  "class": "navSubTitile"
};
var _hoisted_6 = {
  "class": "navSubCardItem"
};
var _hoisted_7 = {
  "class": "navItem"
};
var _hoisted_8 = {
  "class": "left"
};
var _hoisted_9 = {
  "class": "right"
};
var _hoisted_10 = {
  "class": "rightTitle"
};
var _hoisted_11 = {
  "class": "rightDetails"
};
import { ref, onMounted, computed } from 'vue';
import { navData } from "/public/data/nav";
import { navDataEn } from "/public/data/navEn";
import { useI18n } from 'vue-i18n';
export default {
  __name: 'index',
  setup: function setup(__props) {
    var _useI18n = useI18n(),
      locale = _useI18n.locale,
      t = _useI18n.t;
    // 当前选择的语言
    var langVal = ref("");
    // 选择语言(使用计算属性才能下拉实时变化)
    var languagesOptions = computed(function () {
      return [{
        label: t('videoToCover.languageSetting.options.english'),
        value: 'en'
      }, {
        label: t('videoToCover.languageSetting.options.chinese'),
        value: 'zh'
      }];
    });
    // 检测语言并初始化数据
    var checkLangFunc = function checkLangFunc() {
      if (locale.value == "en") {
        navDataRef.value = navDataEn;
        initNavData();
      } else {
        navDataRef.value = navData;
        initNavData();
      }
    };
    // 点击语言选项
    var selectItemHandleClick = function selectItemHandleClick() {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      langVal.value = item.value || "en";
      console.log("langVal", langVal.value);
      locale.value = langVal.value;
      localStorage.setItem('lang', langVal.value);
      checkLangFunc();
    };
    var navDataRef = ref({});
    var tabData = ref([]);
    var tabIndex = ref('0-0');
    var linkTo = function linkTo() {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      window.open(item.linkUrl || "https://dsx2016.com", "_blank");
    };
    var handleSelect = function handleSelect(e) {
      console.log("\u5F53\u524D\u9009\u62E9\u7684\u83DC\u5355", e);
      var firstIndex = e.charAt(0);
      var lastIndex = e.charAt(2);
      tabData.value = navDataRef.value[firstIndex].menu.subMenu[lastIndex].tabs;
    };
    var initNavData = function initNavData() {
      tabData.value = navDataRef.value[0].menu.subMenu[0].tabs;
    };
    onMounted(function () {
      // 初始化数据
      checkLangFunc();
    });
    return function (_ctx, _cache) {
      var _component_el_menu_item = _resolveComponent("el-menu-item");
      var _component_el_sub_menu = _resolveComponent("el-sub-menu");
      var _component_el_menu = _resolveComponent("el-menu");
      var _component_el_col = _resolveComponent("el-col");
      var _component_arrow_down = _resolveComponent("arrow-down");
      var _component_el_icon = _resolveComponent("el-icon");
      var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      var _component_el_dropdown = _resolveComponent("el-dropdown");
      var _component_Link = _resolveComponent("Link");
      var _component_el_card = _resolveComponent("el-card");
      var _component_el_tab_pane = _resolveComponent("el-tab-pane");
      var _component_el_tabs = _resolveComponent("el-tabs");
      var _component_el_row = _resolveComponent("el-row");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
        "class": "rowCon"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_col, {
            span: 4
          }, {
            "default": _withCtx(function () {
              return [navDataRef.value && navDataRef.value.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_menu, {
                "default-active": tabIndex.value,
                onSelect: handleSelect
              }, {
                "default": _withCtx(function () {
                  return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navDataRef.value, function (item, index) {
                    return _openBlock(), _createBlock(_component_el_sub_menu, {
                      key: index,
                      index: index + ''
                    }, {
                      title: _withCtx(function () {
                        return [_createElementVNode("span", null, _toDisplayString(item.menu.title), 1)];
                      }),
                      "default": _withCtx(function () {
                        return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.menu.subMenu, function (item2, index2) {
                          return _openBlock(), _createBlock(_component_el_menu_item, {
                            key: index + '-' + index2,
                            index: index + '-' + index2
                          }, {
                            "default": _withCtx(function () {
                              return [_createTextVNode(_toDisplayString(item2.title), 1)];
                            }),
                            _: 2
                          }, 1032, ["index"]);
                        }), 128))];
                      }),
                      _: 2
                    }, 1032, ["index"]);
                  }), 128))];
                }),
                _: 1
              }, 8, ["default-active"])])) : _createCommentVNode("", true)];
            }),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 20
          }, {
            "default": _withCtx(function () {
              return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_dropdown, {
                style: {
                  "position": "absolute",
                  "top": "0",
                  "right": "0",
                  "z-index": "9999"
                }
              }, {
                dropdown: _withCtx(function () {
                  return [_createVNode(_component_el_dropdown_menu, null, {
                    "default": _withCtx(function () {
                      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(languagesOptions), function (item, index) {
                        return _openBlock(), _createBlock(_component_el_dropdown_item, {
                          key: index,
                          onClick: function onClick($event) {
                            return selectItemHandleClick(item);
                          },
                          style: _normalizeStyle({
                            color: item.value == langVal.value ? "rgb(121.3,187.1,255)" : "#212529"
                          })
                        }, {
                          "default": _withCtx(function () {
                            return [_createTextVNode(_toDisplayString(item.label), 1)];
                          }),
                          _: 2
                        }, 1032, ["onClick", "style"]);
                      }), 128))];
                    }),
                    _: 1
                  })];
                }),
                "default": _withCtx(function () {
                  return [_createElementVNode("span", _hoisted_4, [_createTextVNode(_toDisplayString(_ctx.$t('videoToCover.languageSetting.title')) + " ", 1), _createVNode(_component_el_icon, {
                    "class": "el-icon--right"
                  }, {
                    "default": _withCtx(function () {
                      return [_createVNode(_component_arrow_down)];
                    }),
                    _: 1
                  })])];
                }),
                _: 1
              }), tabData.value && tabData.value.length > 0 ? (_openBlock(), _createBlock(_component_el_tabs, {
                key: 0,
                type: "card"
              }, {
                "default": _withCtx(function () {
                  return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabData.value, function (item, index) {
                    return _openBlock(), _createBlock(_component_el_tab_pane, {
                      key: index,
                      label: item.tabTitle
                    }, {
                      "default": _withCtx(function () {
                        return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.navArr, function (item2, index2) {
                          return _openBlock(), _createElementBlock("div", {
                            key: index2,
                            "class": "tabPaneCon"
                          }, [_createElementVNode("div", _hoisted_5, _toDisplayString(item2.title), 1), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.linkArr, function (item3, index3) {
                            return _openBlock(), _createBlock(_component_el_card, {
                              key: index3,
                              style: {
                                "width": "300px"
                              },
                              onClick: function onClick($event) {
                                return linkTo(item3);
                              },
                              "class": "cardItem"
                            }, {
                              "default": _withCtx(function () {
                                return [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_el_icon, null, {
                                  "default": _withCtx(function () {
                                    return [_createVNode(_component_Link)];
                                  }),
                                  _: 1
                                })]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(item3.title), 1), _createElementVNode("div", _hoisted_11, _toDisplayString(item3.info), 1)])])];
                              }),
                              _: 2
                            }, 1032, ["onClick"]);
                          }), 128))])]);
                        }), 128))];
                      }),
                      _: 2
                    }, 1032, ["label"]);
                  }), 128))];
                }),
                _: 1
              })) : _createCommentVNode("", true)])];
            }),
            _: 1
          })];
        }),
        _: 1
      })]);
    };
  }
};