export default {
  videoToCover: {
    title: `Traditional to Simplified`,
    uploadTips: `Drag and drop files or click to upload`,
    typeTitle: `Audio Format`,
    transBtnText: `Start Conversion`,
    downloadBtnText: `Download subtitles`,
    msgTips: {
      emptyFile: `Please select file`,
      downloadSuccess: `Download Success`,
    },
    languageSetting: {
      title: `Select language`,
      options: {
        english: `English`,
        chinese: `Simplified Chinese`,
      },
    },
  },
};
