// zh.js
export default {
  videoToCover: {
    title: `繁体转简体`,
    uploadTips:`拖拽文件或者点击上传`,
    typeTitle:`音频格式`,
    transBtnText:`开始转换`,
    downloadBtnText:`下载字幕`,
    msgTips:{
        emptyFile:`请选择文件`,
        downloadSuccess:`下载成功`
    },

    languageSetting: {
      title: `选择语言`,
      options: {
        english: `英语`,
        chinese: `简体中文`,
      },
    },
  },
};
